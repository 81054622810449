import request from '@/utils/request'
const isProd = process.env.NODE_ENV === 'production'


const pUserApi = {
	getList: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=getUserList',
	_changeStatus: (isProd ? '' : '/api') + '/newAdmin.php?c=Operate&a=setUserStatus',
}

/*平台用户*/
export function getList(parameter) {
	return request({
		url: pUserApi.getList,
		method: 'post',
		data: parameter
	})
}

/*禁用*/
export function _changeStatus(parameter) {
	return request({
		url: pUserApi._changeStatus,
		method: 'post',
		data: parameter
	})
}