<template>
    <page-header-wrapper>
        <template slot="extra"></template>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper">
                <a-form layout="inline" v-action:query>
                    <a-row :gutter="24">
                        <a-col :xs="24" :sm="24" :md="14" :lg="11" :xl="10" :xxl="6" >
                            <a-form-item label="状态：">
                               <a-select v-model="queryParam.status" style="width: 120px">
                                    <a-select-option :value="item.id" v-for="(item,index) in statusMap" :key="index">
                                       {{item.text}}
                                    </a-select-option>
                                </a-select>
								<a-select v-model="queryParam.isbind" style="width: 120px">
								    <a-select-option :value="item.id" v-for="(item,index) in bindMap" :key="index">
								        {{item.text}}
								    </a-select-option>
								 </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xs="24" :sm="24" :md="10" :lg="8" :xl="7" :xxl="6" >
                            <a-form-item label="关键词">
                            	<a-input-group compact>
                            		<a-select default-value="1" v-model="queryParam.keyType">
                            			<a-select-option value="1">
                            				户名
                            			</a-select-option>
                            			<a-select-option value="2">
                            				户号
                            			</a-select-option>
                            		</a-select>
                            		<a-input style="width: 50%" placeholder="请输入" v-model="queryParam.keyword" />
                            	</a-input-group>
                            </a-form-item>
                        </a-col>
                        <a-col :xs="24" :sm="24" :md="8" :lg="4" :xl="6" :xxl="6" >
                            <span class="table-page-search-submitButtons">
                                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>

            <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" showPagination="auto" :pageNum="pageNum"  :scroll="{ x: '100%' }">
                <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
				<span slot="state" slot-scope="status">
					<a-tag color="#87d068" v-if="status==1">启用</a-tag>
					<a-tag color="rgb(156, 154, 154)" v-else-if="status==2">禁用</a-tag>
				</span>
                <span slot="source" slot-scope="data_source">
					<a-tag :color="getSource(data_source).color">{{getSource(data_source).name}}</a-tag>
				</span>
				<span slot="name" slot-scope="nickname,record">
					<img :src="record.avatar_url" alt="" class="headimg" v-if="record.avatar_url&&record.avatar_url!='-'">
					<span>{{nickname}}</span>
				</span>
				<span slot="sex" slot-scope="sex,record">
					<span>{{sex==1?'男':(sex==2?'女':'-')}}</span>
				</span>
				<span slot="customer" slot-scope="customer">
					<template v-if="customer.length>0">
						<span v-for="(item,index) in customer" :key="index">
							<a-tooltip placement="topLeft">
							    <template slot="title">
							        <span class="">{{item.name}}</span>
							    </template>
							    <span class="customer_id">{{item.id}}</span>
							</a-tooltip>
							<a-divider type="vertical" v-show="index<customer.length-1" />
						</span>
					</template>
					<template v-else>
						--
					</template>
				</span>
                <span slot="time" slot-scope="update_time">
                    {{update_time | time}}
                </span>
                <span slot="operation">
                    --
                </span>
                <span slot="action" slot-scope="text, record">
                    <template>
                        <a @click="changeStatus(record,2,record.data_source)" v-if="record.status==1" v-action:setSatatus>禁用</a>
						<a @click="changeStatus(record,1,record.data_source)" v-if="record.status==2" v-action:setSatatus>启用</a>
                    </template>
                </span>
            </s-table>
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue'
	import { getUrlKey,getSource } from '@/utils'
    import {
        STable,
        Ellipsis
    } from '@/components'
    import {
        getList,
		_changeStatus
    } from '@/api/puser'

    const columns = [
        {
            title: '序号',
            scopedSlots: {
                customRender: 'serial'
            },
            align:'center',
            width:'80px'
        },
        {
            title: '状态',
            dataIndex: 'status',
            align:'center',
			scopedSlots: {
			    customRender: 'state'
			},
			width:'80px'
        },
        {
			title: '数据来源',
			dataIndex:'data_source',
			scopedSlots: {
				customRender: 'source'
			},
			align:'center',
			width:'100px'
		},
		{
		    title: '用户昵称',
		    dataIndex: 'nickname',
			scopedSlots: {
			    customRender: 'name'
			},
		    align:'left',
			width:'220px'
		},
		{
		    title: '性别',
		    dataIndex: 'sex',
			scopedSlots: {
			    customRender: 'sex'
			},
		    align:'center',
			width:'80px'
		},
        {
            title: '绑定户号',
            dataIndex: 'customer',
            align:'left',
            scopedSlots: {
                customRender: 'customer'
            },
			width:"320px"
        },
		{
		    title: '上次登录时间',
		    dataIndex: 'update_time',
		    align:'center',
		    scopedSlots: {
		        customRender: 'time'
		    },
			width:"200px"
		},
        {
            title: '操作',
            dataIndex: 'action',
            width: '120px',
            scopedSlots: {
                customRender: 'action'
            },
            align:'center'
        }
    ]

    const statusMap = {
        '0': {
            status: 'default',
            text: '全部'
        },
        '1': {
            status: 'success',
            text: '启用'
        },
        '2': {
            status: 'error',
            text: '禁用'
        }
    }

    export default {
        name: '',
        components: {
            STable,
            Ellipsis
        },
        data() {
            this.columns = columns
            return {
                visible: false,
                advanced: false,
                // 查询参数
                queryParam: {
                    customer_id: '',
					customer_name:'',
                    status:'0',
					keyword:'',
					keyType:'1',
					isbind:'0'
                },
                // 加载数据方法 必须为 Promise 对象
                loadData: parameter => {
                    //console.log('parameter',parameter)
					this.queryParam.status==0?'':this.queryParam.status
					let temp = this.queryParam
					if(temp.keyType==1){
						temp.customer_name = temp.keyword
						temp.customer_id = ''
					}else{
						temp.customer_name = ''
						temp.customer_id = temp.keyword
					}
					this.queryParam = temp
                    const requestParameters = Object.assign({}, parameter, this.queryParam)
					if(requestParameters.status==0){
						requestParameters.status=''
					}
					if(requestParameters.isbind == 0){
						requestParameters.isbind = ''
					} 
                    return getList(requestParameters).then(res => {
                        return res.data
                    })
                },
				statusMap:[
					{id:'0',text:'全部'},
					{id:'1',text:'启用'},
					{id:'2',text:'禁用'}
				],
				bindMap:[
					{id:'0',text:'全部'},
					{id:'1',text:'已绑定'},
					{id:'2',text:'未绑定'}
				],
				pageNum:0
            }
        },
		created(){
			this.pageNum = Number(getUrlKey('p')||0)
		},
        methods: {
            getSource:getSource,
            changeStatus(data,status,data_source){   //禁用
				this.pageNum = Number(getUrlKey('p'))||0
				let params = {
					id:data.id,
					status:status,
                    data_source
				}
				_changeStatus(params).then(res =>{
					//console.log('res',res);
					if(res.code==200){
						this.$refs.table.refresh(true)
						this.$message.success(res.msg)
					}
				})
            },
            reset() { //重置
                this.queryParam.status = '0'
				this.queryParam.keyword = ''
				this.queryParam.keyType = '1'
				this.queryParam.customer_name = ''
				this.queryParam.customer_id = ''
				this.queryParam.isbind = '0'
                this.$refs.table.refresh(true)
            }
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
                } else {
                    return t
                }
            },
            statusFilter(type) {
                return statusMap[type].text
            },
            statusTypeFilter(type) {
                return statusMap[type].status
            }
        }
    }
</script>

<style scoped>
.deliv_item{display:flex;align-items: center;margin-top: 36px;}
.deliv_item_label{width: 120px;}
.deliv_item_label i{font-style: normal;color: #FF3B30;}
.modal_item{display: flex;margin-top: 10px;}
.modal_item div:nth-child(1){min-width: 112px;}
.modal_item div:nth-child(2) img{margin-top: 5px;}

.item{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}
.item_label{
	min-width:100px;
}
.item_label i{
	font-style: normal;
	color: #FF3B30;
	margin-right: 3px;
}
.item_main{
	min-width: 1px;
	width: 100%;
}
.headimg{
	width: 21px;
	height: 21px;
	border-radius:50%;
	margin-right: 10px;
}
.customer_id{cursor: pointer;}
</style>s